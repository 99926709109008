
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import Error404 from '../src/components/Error404';
import { withNavigation } from '../src/utils/contentfulHelpers';
const propTypes = {
    statusCode: PropTypes.number,
    router: PropTypes.shape({
        push: PropTypes.any,
        asPath: PropTypes.any
    }).isRequired
};
const ErrorPage = ({ statusCode = 404 }) => {
    return <Error404 statusCode={statusCode}/>;
};
async function getStaticProps() {
    const props = await withNavigation();
    return {
        props,
        revalidate: Number(process.env.STATIC_REVALIDATION_TIMEOUT) || 3600
    };
}
ErrorPage.propTypes = propTypes;
export default withRouter(ErrorPage);

    async function __Next_Translate__getStaticProps__195ca0c92d1__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195ca0c92d1__ as getStaticProps }
  